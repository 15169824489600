import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import AdminLoginWrapper from './pages/AdminView/AdminLogin/AdminLoginWrapper';
import Home from './pages/Home';
import Nosotros from './pages/Nosotros';
import Contacto from './pages/Contacto';
import Productos from './pages/Productos';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Detalle from './pages/Detalle';
import AdminHome from './pages/AdminView/AdminHome/AdminHome';
import AdminProductosCRUD from './pages/AdminView/AdminProductosCRUD/AdminProductosCRUD';
import AdminProductosAgregar from './pages/AdminView/AdminProductosCRUD/AgregarProductos/AdminProductosAgregar';
import AdminProductosEditar from './pages/AdminView/AdminProductosCRUD/EditarProductos/AdminProductosEditar';
import AvisoLegal from './pages/PoliticasView/AvisoLegal/AvisoLegal';
import PoliticaDePrivacidad from './pages/PoliticasView/PoliticaDePrivacidad/PoliticaDePrivacidad';
import TerminosYCondiciones from './pages/PoliticasView/TerminosYCondiciones/TerminosYCondiciones';
import PoliticasDeUsoYLicencias from './pages/PoliticasView/UsoDeLicencias/PoliticasDeUsoYLicencias';

// Componente que maneja el layout principal
function MainLayout() {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith("/admin");

  return (
    <div>
      <div className="top-bar">
        <Navbar />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Nosotros" element={<Nosotros />} />
        <Route path="/Contacto" element={<Contacto />} />
        <Route path="/Productos" element={<Productos />} />
        <Route path="/Productos/:id" element={<Detalle />} /> {/* Ruta dinámica */}
        <Route path="/admin/:token" element={<AdminLoginWrapper />} />
        <Route path="/admin/home" element={<AdminHome />} />
        <Route path="/admin/productos" element={<AdminProductosCRUD />} />
        <Route path="/admin/productos_agregar" element={<AdminProductosAgregar />} />
        <Route path="/admin/productos_editar/:id" element={<AdminProductosEditar />} />
        <Route path="/aviso_legal" element={<AvisoLegal />} />
        <Route path="/politica_privacidad" element={<PoliticaDePrivacidad />} />
        <Route path="/terminos_y_condiciones" element={<TerminosYCondiciones />} />
        <Route path="/politicas_de_uso_y_licencias" element={<PoliticasDeUsoYLicencias />} />
      </Routes>
      {/* Renderiza el Footer solo si no estás en una ruta de admin */}
      {!isAdminRoute && <Footer />}
    </div>
  );
}

function App() {
  return (
    <Router>
      <MainLayout />
    </Router>
  );
}

export default App;
