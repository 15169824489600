// AdminProductosAgregar.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminProductosAgregar.css";
import AuthVerifier from "../../AuthVerifier";

const AdminProductosAgregar = () => {
    const navigate = useNavigate();
    const [nombre, setNombre] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [estado, setEstado] = useState(true);
    const [precio, setPrecio] = useState("");
    const [imagen, setImagen] = useState(null);
    const [error, setError] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const authToken = localStorage.getItem("authToken");
            const formData = new FormData();

            formData.append("nombre", nombre);
            formData.append("descripcion", descripcion);
            formData.append("estado", estado);
            formData.append("precio", precio);
            formData.append("imagen", imagen);

            const response = await fetch(`https://riseitservice.com/api/agregar-producto/`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${authToken}`
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error("Error al agregar el producto");
            }

            const result = await response.json();
            console.log("Producto agregado:", result);

            navigate("/admin/productos");
        } catch (error) {
            console.error("Error:", error);
            setError("Hubo un problema al agregar el producto");
        }
    };

    return (
        <AuthVerifier>
            <div className="container-todo">
                <div className="agregar-producto-container">
                    <h2>Agregar Nuevo Producto</h2>
                    {error && <p className="error-message">{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <label>
                            Nombre del producto:
                            <input
                                type="text"
                                value={nombre}
                                onChange={(e) => setNombre(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            Descripción:
                            <textarea
                                value={descripcion}
                                onChange={(e) => setDescripcion(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            Estado:
                            <select value={estado} onChange={(e) => setEstado(e.target.value === "true")}>
                                <option value="true">Activo</option>
                                <option value="false">Inactivo</option>
                            </select>
                        </label>
                        <label>
                            Precio:
                            <input
                                type="number"
                                value={precio}
                                onChange={(e) => setPrecio(e.target.value)}
                                required
                                step="0.01"
                            />
                        </label>
                        <label>
                            Imagen:
                            <input
                                type="file"
                                onChange={(e) => setImagen(e.target.files[0])}
                                accept="image/*"
                                required
                            />
                        </label>
                        <button type="submit" className="add-product-button">Agregar Producto</button>
                    </form>
                </div>
            </div>
        </AuthVerifier>
    );
};

export default AdminProductosAgregar;
