// AvisoLegal.js
import React from 'react';
import './AvisoLegal.css'; // Puedes agregar un archivo CSS para estilos personalizados

function AvisoLegal() {
  return (
    <div className="aviso-legal-container">
      <h1>Aviso Legal de RISE</h1>
      
      <section>
        <h2>1. Identificación del Titular del Sitio Web</h2>
        <p>
          El presente Aviso Legal regula el uso del sitio web de <strong>RISE</strong> (en adelante, “la Empresa”), accesible desde la dirección 
          <a href="https://www.rise.com"> www.rise.com</a>. Este sitio es propiedad y está gestionado por <strong>RISE</strong>, una empresa especializada en el desarrollo de software y aplicaciones de escritorio.
        </p>
      </section>

      <section>
        <h2>2. Información de Contacto</h2>
        <p><strong>Nombre de la Empresa:</strong> Research, IT Services and Engineering, RISE SpA.</p>
        <p><strong>Domicilio:</strong> 13 Norte 853 Viña del Mar</p>
        <p><strong>Teléfono de contacto:</strong> +569 56433387</p>
        <p><strong>Correo electrónico:</strong> <a href="mailto:rise@riseitservices.com">rise@riseitservices.com</a></p>
      </section>

      <section>
        <h2>3. Uso del Sitio Web</h2>
        <p>
          El sitio web tiene como propósito ofrecer información sobre los productos y servicios de desarrollo de software de la Empresa. Al acceder y utilizar el sitio, los usuarios aceptan los términos y condiciones establecidos en este Aviso Legal.
        </p>
      </section>

      <section>
        <h2>4. Propiedad Intelectual e Industrial</h2>
        <p>
          Todo el contenido del sitio web, incluyendo, sin limitación, textos, gráficos, logotipos, imágenes, y cualquier otro material, es propiedad de RISE o cuenta con las licencias necesarias para su uso. Queda prohibida su reproducción, distribución, comunicación pública o modificación sin el consentimiento expreso de la Empresa.
        </p>
      </section>

      <section>
        <h2>5. Protección de Datos Personales</h2>
        <p>
          RISE cumple con la normativa vigente en materia de protección de datos personales, y garantiza un tratamiento seguro y adecuado de los datos que recaba a través del sitio web. Los datos personales proporcionados por los usuarios en formularios de contacto o a través de otros medios serán tratados conforme a la <strong>Política de Privacidad</strong> de la Empresa.
        </p>
      </section>

      <section>
        <h2>6. Limitación de Responsabilidad</h2>
        <p>
          RISE no se hace responsable de los daños y perjuicios que pudieran derivarse de la falta de disponibilidad o continuidad del sitio web, ni de los errores u omisiones en el contenido proporcionado. Tampoco se garantiza que el sitio esté libre de virus o de otros elementos que pudieran alterar el sistema informático del usuario.
        </p>
      </section>

      <section>
        <h2>7. Enlaces a Terceros</h2>
        <p>
          El sitio web puede contener enlaces a sitios web de terceros, los cuales son gestionados por otras empresas. RISE no asume ninguna responsabilidad sobre los contenidos, políticas o prácticas de estos sitios externos.
        </p>
      </section>

      <section>
        <h2>8. Modificaciones al Aviso Legal</h2>
        <p>
          RISE se reserva el derecho a modificar el presente Aviso Legal en cualquier momento. Las modificaciones entrarán en vigor en el momento en que se publiquen en el sitio web.
        </p>
      </section>

      <section>
        <h2>9. Legislación Aplicable y Jurisdicción</h2>
        <p>
          El presente Aviso Legal se regirá e interpretará de acuerdo con la legislación aplicable en [indicar el país]. En caso de disputa o conflicto, las partes se someterán a los tribunales competentes de [indicar la ciudad o región], salvo que la legislación aplicable disponga otra cosa.
        </p>
      </section>
    </div>
  );
}

export default AvisoLegal;
