// PoliticaDePrivacidad.js
import React from 'react';
import './PoliticaDePrivacidad.css'; // Archivo CSS para estilos personalizados

function PoliticaDePrivacidad() {
  return (
    <div className="politica-de-privacidad-container">
      <h1>Política de Privacidad de RISE</h1>
      
      <section>
        <h2>1. Introducción</h2>
        <p>
          La presente Política de Privacidad establece los términos en los que <strong>RISE</strong> (en adelante, “la Empresa”) utiliza y protege la información personal que es proporcionada por los usuarios al utilizar este sitio web. RISE se compromete a garantizar que la privacidad de sus usuarios esté protegida y, al proporcionar su información personal, usted acepta los términos de esta Política de Privacidad.
        </p>
      </section>

      <section>
        <h2>2. Datos que Recopilamos</h2>
        <p>La Empresa puede recopilar la siguiente información:</p>
        <ul>
          <li>Información de contacto, como nombre, dirección de correo electrónico y número de teléfono.</li>
          <li>Datos sobre el uso del sitio web y las interacciones con los servicios de RISE.</li>
          <li>Otra información relevante para encuestas de clientes y ofertas promocionales.</li>
        </ul>
      </section>

      <section>
        <h2>3. Uso de la Información Recopilada</h2>
        <p>La información recopilada se utiliza principalmente para:</p>
        <ul>
          <li>Proveer, operar y mantener nuestros servicios de manera efectiva.</li>
          <li>Mejorar la experiencia del usuario, personalizando el contenido y las recomendaciones.</li>
          <li>Contactar a los usuarios con información sobre actualizaciones de servicios, nuevos productos, ofertas y otros asuntos de interés.</li>
          <li>Cumplir con obligaciones legales y normativas.</li>
        </ul>
      </section>

      <section>
        <h2>4. Protección de la Información</h2>
        <p>
          RISE está comprometida con la seguridad de los datos de los usuarios. Para evitar el acceso no autorizado o la divulgación de información personal, RISE ha implementado medidas de seguridad tanto físicas como electrónicas y administrativas para proteger la información que recopila en línea.
        </p>
      </section>

      <section>
        <h2>5. Uso de Cookies</h2>
        <p>
          Este sitio web utiliza cookies para mejorar la experiencia del usuario y ofrecer un servicio más personalizado. Una cookie es un archivo que se almacena en su dispositivo y ayuda a analizar el tráfico web. Puede aceptar o rechazar el uso de cookies en cualquier momento a través de la configuración de su navegador, aunque esto puede afectar la funcionalidad del sitio.
        </p>
      </section>

      <section>
        <h2>6. Control de su Información Personal</h2>
        <p>
          Usted tiene derecho a acceder, rectificar, actualizar y, en ciertos casos, solicitar la eliminación de su información personal. Si desea ejercer estos derechos, puede contactarnos a través de nuestro correo electrónico: <a href="mailto:rise@riseitservices.com">rise@riseitservices.com</a>.
        </p>
      </section>

      <section>
        <h2>7. Enlaces a Terceros</h2>
        <p>
          Este sitio web puede contener enlaces a otros sitios que podrían ser de su interés. Sin embargo, una vez que usted haga clic en estos enlaces y abandone nuestro sitio, RISE no tiene control sobre el sitio web al que es redirigido y, por lo tanto, no es responsable de la protección y privacidad de su información en esos sitios externos.
        </p>
      </section>

      <section>
        <h2>8. Cambios en la Política de Privacidad</h2>
        <p>
          RISE se reserva el derecho a modificar esta Política de Privacidad en cualquier momento. Las modificaciones entrarán en vigor en el momento de su publicación en el sitio web, y se recomienda a los usuarios revisar esta página periódicamente para asegurarse de estar de acuerdo con los cambios.
        </p>
      </section>

      <section>
        <h2>9. Legislación Aplicable y Contacto</h2>
        <p>
          Esta Política de Privacidad se regirá e interpretará de acuerdo con la legislación vigente en Chile. Para cualquier duda o solicitud relacionada con la privacidad, por favor, contáctenos a través de nuestro correo electrónico: <a href="mailto:rise@riseitservices.com">rise@riseitservices.com</a>.
        </p>
      </section>
    </div>
  );
}

export default PoliticaDePrivacidad;
