import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu(); // Cierra el menú si se hace clic fuera de él
      }
    };

    // Escucha el evento de clic en el documento
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Elimina el event listener cuando el componente se desmonta
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <nav className="navbar">
      <img 
        onClick={handleLogoClick} 
        src="/assets/RISE2.png" 
        alt="Logo de la compañía" 
        className="navbar-logo" 
      />
      <div className="navbar-toggle" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul ref={menuRef} className={`navbar-list ${menuOpen ? 'active' : ''}`}>
        <li><Link to="/" onClick={closeMenu}>Inicio</Link></li>
        <li><Link to="/Nosotros" onClick={closeMenu}>Nosotros</Link></li>
        <li><Link to="/contacto" onClick={closeMenu}>Contacto</Link></li>
        <li><Link to="/Productos" onClick={closeMenu}>Productos</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
