import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Contacto.css';


function Contacto() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productoInteres = queryParams.get('producto') || '';

  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    telefono: '',
    producto_interes: productoInteres,
    mensaje: productoInteres
      ? `Quisiera explorar más a fondo el proyecto ${productoInteres} para comprender cómo puede beneficiar a mi negocio. ¿Podrían enviarme información detallada y una cotización?`
      : '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://riseitservice.com/api/contacto/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success('Correo enviado, muchas gracias por contactarnos. Si deseas llamar directamente y consultar puedes llamar a +569 56433387');
        setFormData({ nombre: '', correo: '', telefono: '', producto_interes: '', mensaje: '' });
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('Hubo un error al enviar el formulario');
      });
  };

  return (
    <div className="contact-container">
      <h1>Contacto</h1>
      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <label>
            Nombre completo*
            <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} required />
          </label>
          <label>
            Correo *
            <input type="email" name="correo" value={formData.correo} onChange={handleChange} required />
          </label>
          <label>
            Número telefónico *
            <input type="tel" name="telefono" value={formData.telefono} onChange={handleChange} required />
          </label>
          <label>
            Producto de interés
            <input type="text" name="producto_interes" value={formData.producto_interes} onChange={handleChange} />
          </label>
          <label>
            Mensaje
            <textarea name="mensaje" value={formData.mensaje} onChange={handleChange} />
          </label>
          <button type="submit">Enviar</button>
        </form>
      </div>
      {/* Agrega el contenedor de notificaciones */}
      <ToastContainer
        position="top-center" // Define la posición básica
        autoClose={false}      // Evita cierre automático, requiere clic
        hideProgressBar        // Oculta barra de progreso
        closeOnClick
        pauseOnHover
        draggable={false}
        style={{
          top: '50%', // Centra verticalmente
          left: '50%', // Centra horizontalmente
          width: 'auto', 
          maxWidth: '600px', 
          fontSize: '18px', 
          transform: 'translate(-50%, -50%)', // Ajusta el origen
        }}
      />

    </div>
  );
}

export default Contacto;
