// AdminHome.js
import { useNavigate } from "react-router-dom";
import "./AdminHome.css"; // Importa el archivo CSS
import React, { useEffect, useCallback } from "react";
import AuthVerifier from "../AuthVerifier";

const AdminHome = () => {
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      console.log(authToken);

      // Aquí puedes agregar lógica adicional para usar authToken
    } catch (error) {
      console.error("Error al llamar a la API:", error);
      navigate("/");
    }
  }, [navigate]);

  // useEffect para ejecutar fetchData cuando el componente se monte
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <AuthVerifier>
      <div className="admin-home-container">
        <div className="admin-home-content">
          <h1>Panel de Administración</h1>
          <p>Bienvenido al panel de administración. Aquí puedes gestionar los recursos del sistema.</p>
          
          <div className="button-container">
            <button className="admin-button" onClick={() => navigate("/admin/productos")}>
              Gestionar Productos
            </button>
          </div>
        </div>
      </div>
    </AuthVerifier>
  );
};

export default AdminHome;
