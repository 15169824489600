import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import AdminLogin from "./AdminLogin";

function AdminLoginWrapper() {
  const { token } = useParams();  //Const que se enviará en el fetch para validar el token
  const [tokenValido, setTokenValido] = useState(null); // null mientras se valida

  useEffect(() => {
    const validarToken = async () => {
      try {
        
        const response = await fetch(`https://riseitservice.com/api/validar-token/${token}/`); //Enviamos el token y en django (puerto 8000) se validará si el token es correcto
        const data = await response.json();
        if (data.valid) {
          setTokenValido(true);
        } else {
          setTokenValido(false);
        }
      } catch (error) {
        console.log("Token enviado:", token);
        console.error("Error al validar el token:", error);
        setTokenValido(false);
      }
    };

    validarToken();
  }, [token]);

  if (tokenValido === null) return <p>Validando token...</p>;

  if (!tokenValido) return <Navigate to="/" />;

  return <AdminLogin />;
}

export default AdminLoginWrapper;
