// Productos.js
import React, { useState, useEffect } from 'react';
import './Productos.css';
import { Link } from 'react-router-dom';

function Productos() {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://riseitservice.com/api/productos-publicos/')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error al obtener los productos');
        }
        return response.json();
      })
      .then((data) => {
        setProducts(data);
        setFilteredProducts(data);
      })
      .catch((error) => {
        console.error('Error al obtener productos:', error);
        setError('Hubo un problema al cargar los productos');
      });
  }, []);

  const filterProducts = () => {
    const updatedProducts = products.filter((product) =>
      product.nombre ? product.nombre.toLowerCase().includes(search.toLowerCase()) : false
    );
    setFilteredProducts(updatedProducts);
  };

  const handleSearch = () => {
    filterProducts(); // Ejecuta la búsqueda al hacer clic en el botón de búsqueda
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch(); // Ejecuta la búsqueda al presionar Enter
    }
  };

  return (
    <div className="products-page">
      <div className="search-container">
        <input
          type="text"
          placeholder="Buscar productos..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleKeyPress}
          className="search-bar"
        />
        <button className="search-button" onClick={handleSearch}>
          🔍
        </button>
      </div>

      {error ? (
        <div className="no-products-message">{error}</div>
      ) : filteredProducts.length === 0 ? (
        <div className="no-products-message">
          Ups, parece que no tenemos proyectos que coincidan con tu búsqueda. Prueba con otro término o explora nuestras categorías para encontrar lo que buscas.
        </div>
      ) : (
        <div className="product-grid">
          {filteredProducts.map((product) => (
            <Link to={`/Productos/${product.id}`} key={product.id} className="product-card">
              <img
                src={product.imagen}
                alt={product.nombre}
                className="product-image-principal"
                loading="lazy" // Aquí se agrega el lazy loading
              />
              <h3 className="product-name">{product.nombre}</h3>
              <p className="product-price">
                {product.precio.toLocaleString('es-CL', {
                  style: 'currency',
                  currency: 'CLP',
                  minimumFractionDigits: 0,
                })}
                CLP
              </p>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default Productos;
