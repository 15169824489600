import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminProductosCRUD.css";
import AuthVerifier from "../AuthVerifier";
import { FaEdit, FaTrash } from "react-icons/fa";

const AdminProductosCRUD = () => {
    const navigate = useNavigate();
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda
    const [loading, setLoading] = useState(true);

    const rowsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const fetchData = useCallback(async () => {
        try {
            const authToken = localStorage.getItem("authToken");

            const response = await fetch("https://riseitservice.com/api/obtener-productos/", {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });

            if (!response.ok) {
                throw new Error("Error al obtener productos.");
            }

            const result = await response.json();
            setColumns([
                { field: "id", headerName: "ID" },
                { field: "nombre", headerName: "Nombre" },
                { field: "descripcion", headerName: "Descripción" },
                { field: "estado", headerName: "Estado" },
                { field: "precio", headerName: "Precio" },
                { field: "imagen", headerName: "Imagen" },
                { field: "actions", headerName: "Acciones" },
            ]);

            setData(result);
            setLoading(false);
        } catch (error) {
            console.error("Error al llamar a la API:", error);
            navigate("/"); // Redirige al inicio si hay un error
        }
    }, [navigate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleEdit = (id) => {
        navigate(`/admin/productos_editar/${id}`);
    };

    const handleDelete = async (id) => {
        // Mostrar una ventana de confirmación
        const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar este producto?");
        
        if (!confirmDelete) {
            // Si el usuario cancela, no hace nada
            return;
        }
    
        const authToken = localStorage.getItem("authToken");
    
        const response = await fetch(`https://riseitservice.com/api/eliminar-producto/${id}/`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
    
        if (response.ok) {
            setData((prevData) => prevData.filter((producto) => producto.id !== id));
            console.log("Producto eliminado exitosamente.");
        } else {
            console.error("Error al eliminar el producto.");
        }
    };
    

    // Filtrar los productos en función del término de búsqueda
    const filteredData = data.filter((product) =>
        product.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const currentData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    if (loading) {
        return <p>Verificando usuario...</p>;
    }

    return (
        <AuthVerifier>
            <div className="productos-crud-container">
                <div className="productos-crud-content">
                    <h1>Gestión de Productos</h1>
                    <p>Aquí puedes agregar, editar o eliminar productos.</p>

                    {/* Barra de búsqueda */}
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Buscar productos..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-bar"
                        />
                    </div>

                    <div className="data-table-container">
                        <table className="data-table">
                            <thead>
                                <tr>
                                    {columns.map((column) => (
                                        <th key={column.field}>{column.headerName}</th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {currentData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {columns.map((column) => (
                                            <td key={column.field}>
                                                {column.field === "estado" ? (
                                                    row[column.field] ? "Activo" : "Inactivo"
                                                ) : column.field === "imagen" ? (
                                                    <img
                                                        src={row[column.field]}
                                                        alt="Producto"
                                                        className="product-image"
                                                    />
                                                ) : column.field === "actions" ? (
                                                    <div className="action-buttons">
                                                        <button onClick={() => handleEdit(row.id)} className="edit-button">
                                                            <FaEdit /> Editar
                                                        </button>
                                                        <button onClick={() => handleDelete(row.id)} className="delete-button">
                                                            <FaTrash /> Eliminar
                                                        </button>
                                                    </div>
                                                ) : (
                                                    row[column.field]
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination-container">
                            <button 
                                onClick={goToPreviousPage} 
                                disabled={currentPage === 1} 
                                className={`pagination-button ${currentPage === 1 ? "disabled" : ""}`}
                            >
                                Anterior
                            </button>
                            <span className="pagination-info">
                                Página {currentPage} de {totalPages}
                            </span>
                            <button 
                                onClick={goToNextPage} 
                                disabled={currentPage === totalPages} 
                                className={`pagination-button ${currentPage === totalPages ? "disabled" : ""}`}
                            >
                                Siguiente
                            </button>
                        </div>

                        <button onClick={() => navigate("/admin/productos_agregar")} className="add-product-button-crud">
                            Agregar producto
                        </button>
                    </div>
                </div>
            </div>
        </AuthVerifier>
    );
};

export default AdminProductosCRUD;
