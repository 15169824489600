// AdminProductosEditar.js
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./AdminProductosEditar.css";
import AuthVerifier from "../../AuthVerifier";

const AdminProductosEditar = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // Captura el ID del producto desde la URL
    const [nombre, setNombre] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [estado, setEstado] = useState(true);
    const [precio, setPrecio] = useState("");
    const [imagen, setImagen] = useState(null);
    const [error, setError] = useState(null);

    // Cargar datos del producto cuando el componente se monta
    useEffect(() => {
        const fetchProducto = async () => {
            const authToken = localStorage.getItem("authToken");

            try {
                const response = await fetch(`https://riseitservice.com/api/obtener-producto/${id}/`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${authToken}`,
                        "Content-Type": "application/json",
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setNombre(data.nombre);
                    setDescripcion(data.descripcion);
                    setEstado(data.estado);
                    setPrecio(data.precio);
                    setImagen(data.imagen); // Si quieres mostrar la imagen actual, puedes hacerlo
                } else {
                    console.error("Error al obtener los datos del producto.");
                }
            } catch (error) {
                console.error("Error al cargar el producto:", error);
                setError("Hubo un problema al cargar los datos del producto");
            }
        };

        fetchProducto();
    }, [id]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const authToken = localStorage.getItem("authToken");
            const formData = new FormData();

            formData.append("nombre", nombre);
            formData.append("descripcion", descripcion);
            formData.append("estado", estado);
            formData.append("precio", precio);
            if (imagen) {
                formData.append("imagen", imagen); // Solo incluir la imagen si fue seleccionada
            }

            const response = await fetch(`https://riseitservice.com/api/obtener-producto/${id}/`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${authToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Error al editar el producto");
            }

            const result = await response.json();
            console.log("Producto editado:", result);

            navigate("/admin/productos");
        } catch (error) {
            console.error("Error:", error);
            setError("Hubo un problema al editar el producto");
        }
    };

    return (
        <AuthVerifier>
            <div className="container-todo">
                <div className="editar-producto-container">
                    <h2>Editar Producto</h2>
                    {error && <p className="error-message">{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <label>
                            Nombre del producto:
                            <input
                                type="text"
                                value={nombre}
                                onChange={(e) => setNombre(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            Descripción:
                            <textarea
                                value={descripcion}
                                onChange={(e) => setDescripcion(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            Estado:
                            <select value={estado} onChange={(e) => setEstado(e.target.value === "true")}>
                                <option value="true">Activo</option>
                                <option value="false">Inactivo</option>
                            </select>
                        </label>
                        <label>
                            Precio:
                            <input
                                type="number"
                                value={precio}
                                onChange={(e) => setPrecio(e.target.value)}
                                required
                                step="0.01"
                            />
                        </label>
                        <label>
                            Imagen:
                            <input
                                type="file"
                                onChange={(e) => setImagen(e.target.files[0])}
                                accept="image/*"
                            />
                        </label>
                        <button type="submit" className="edit-product-button">Guardar Cambios</button>
                    </form>
                </div>
            </div>
        </AuthVerifier>
    );
};

export default AdminProductosEditar;
