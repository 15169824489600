// AuthVerifier.js
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthVerifier = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const verificarToken = async () => {
            try {
                const authToken = localStorage.getItem("authToken");
                const validationResponse = await fetch(`https://riseitservice.com/api/validar-token-auth/`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${authToken}`,
                        "Content-Type": "application/json",
                    },
                    credentials: "include"
                });

                const validationData = await validationResponse.json();

                if (validationData.valid) {
                    setLoading(false); // Token válido, se puede cargar el contenido
                } else {
                    console.error("Error en el token de autenticación.");
                    navigate("/"); // Redirige al inicio si el token es inválido
                }
            } catch (error) {
                console.error("Error al verificar el token:", error);
                navigate("/"); // Redirige al inicio en caso de error
            }
        };

        verificarToken();
    }, [navigate]);

    if (loading) {
        return <p>Verificando usuario...</p>;
    }

    return <>{children}</>;
};

export default AuthVerifier;
