// PoliticasDeUsoYLicencias.js
import React from 'react';
import './PoliticasDeUsoYLicencias.css'; // Archivo CSS para estilos personalizados

function PoliticasDeUsoYLicencias() {
  return (
    <div className="politicas-de-uso-licencias-container">
      <h1>Políticas de Uso y Licencias de RISE</h1>
      
      <section>
        <h2>1. Introducción</h2>
        <p>
          Las presentes Políticas de Uso y Licencias regulan el acceso y uso de los productos y servicios proporcionados por <strong>RISE</strong>. Al utilizar nuestros productos y servicios, el usuario acepta cumplir con estas políticas. Para cualquier consulta, puede contactarnos en:
        </p>
        <ul>
          <li><strong>Correo electrónico:</strong> <a href="mailto:rise@riseitservices.com">rise@riseitservices.com</a></li>
          <li><strong>Teléfono:</strong> +569 56433387</li>
          <li><strong>Dirección:</strong> 13 Norte 853, Viña del Mar</li>
        </ul>
      </section>

      <section>
        <h2>2. Licencias y Propiedad Intelectual</h2>
        <p>
          Los productos de software y aplicaciones de RISE están protegidos por derechos de autor y otras leyes de propiedad intelectual. Todos los derechos que no se otorguen explícitamente al usuario en estas políticas están reservados por RISE o sus licenciantes.
        </p>
        <ul>
          <li><strong>Licencia de uso:</strong> Al adquirir un producto de RISE, el usuario recibe una licencia de uso personal, limitada, no exclusiva y no transferible para utilizar el producto de acuerdo con las condiciones especificadas en el acuerdo de licencia correspondiente.</li>
          <li><strong>Restricciones de uso:</strong> Queda prohibida la copia, distribución, modificación, ingeniería inversa o cualquier intento de obtener el código fuente de los productos de RISE sin el permiso expreso de la empresa.</li>
        </ul>
      </section>

      <section>
        <h2>3. Uso Aceptable de los Servicios</h2>
        <p>El usuario se compromete a utilizar los productos y servicios de RISE solo para fines legales y de acuerdo con estas políticas. Se prohíbe:</p>
        <ul>
          <li>Usar los productos para actividades ilícitas, fraudulentas o dañinas.</li>
          <li>Alterar, modificar o interferir con el funcionamiento de los productos.</li>
          <li>Distribuir versiones modificadas del software sin la autorización de RISE.</li>
        </ul>
      </section>

      <section>
        <h2>4. Soporte Técnico y Mantenimiento</h2>
        <p>
          RISE ofrece soporte técnico gratuito relacionado con la instalación inicial y configuración básica de sus productos, con el fin de garantizar que el usuario pueda empezar a utilizar el servicio sin inconvenientes. Para recibir esta asistencia, el usuario puede ponerse en contacto a través de <a href="mailto:rise@riseitservices.com">rise@riseitservices.com</a>.
        </p>
        <p>
          Para opciones de <strong>soporte técnico adicional</strong> y <strong>mantenimiento continuo</strong>, RISE ofrece planes y servicios de asistencia que incluyen actualización, optimización y solución de problemas en el uso de largo plazo de nuestros productos, disponibles bajo un contrato de servicio independiente. Los usuarios interesados pueden contactar a nuestro equipo para conocer los paquetes de soporte y mantenimiento que mejor se adapten a sus necesidades.
        </p>
      </section>

      <section>
        <h2>5. Responsabilidad y Garantías</h2>
        <p>
          RISE no garantiza que sus productos estarán libres de errores o interrupciones. La empresa no se hace responsable por daños o pérdidas derivadas del uso de sus productos o de la imposibilidad de utilizarlos.
        </p>
        <ul>
          <li><strong>Limitación de responsabilidad:</strong> En ningún caso RISE será responsable de daños indirectos, especiales o consecuentes que puedan derivarse del uso o imposibilidad de uso de sus productos.</li>
          <li><strong>Exclusión de garantías:</strong> RISE proporciona sus productos “tal cual” y no ofrece garantías adicionales, salvo las expresamente indicadas en el acuerdo de licencia correspondiente.</li>
        </ul>
      </section>

      <section>
        <h2>6. Política de Reembolsos</h2>
        <p>
          La solicitud de reembolsos estará sujeta a los términos establecidos en el contrato de licencia y a la evaluación individual de cada caso. RISE se reserva el derecho de aceptar o rechazar solicitudes de reembolso según lo estipulado en la normativa aplicable.
        </p>
      </section>

      <section>
        <h2>7. Modificación de las Políticas de Uso y Licencias</h2>
        <p>
          RISE se reserva el derecho de modificar estas Políticas de Uso y Licencias en cualquier momento. Las modificaciones serán efectivas desde su publicación en el sitio web de la empresa. Se recomienda a los usuarios revisar periódicamente estas políticas para mantenerse informados de cualquier cambio.
        </p>
      </section>

      <section>
        <h2>8. Contacto</h2>
        <p>Para cualquier consulta relacionada con estas Políticas de Uso y Licencias, puede contactarnos a través de:</p>
        <ul>
          <li><strong>Correo electrónico:</strong> <a href="mailto:rise@riseitservices.com">rise@riseitservices.com</a></li>
          <li><strong>Teléfono:</strong> +569 56433387</li>
          <li><strong>Dirección:</strong> 13 Norte 853, Viña del Mar</li>
        </ul>
      </section>
    </div>
  );
}

export default PoliticasDeUsoYLicencias;
