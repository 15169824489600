// AdminLogin.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminLogin.css"; // Importa el archivo CSS

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Acepta `event` como argumento
  const handleLogin = async (event) => {
    event.preventDefault(); // Evita que el formulario se envíe y recargue la página

    try {
      const response = await fetch(`https://riseitservice.com/api/admin-login/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
        credentials: "include", // Incluir cookies en la solicitud
      });
      const data = await response.json();

      if (response.ok) {
        alert(data.message);
        localStorage.setItem("authToken", data.token); // Guarda el token en el almacenamiento local
        console.log("Todo está bien");
        navigate("/admin/home"); // Redirige a la ruta de AdminView
      } else {
        setError(data.error);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setError("Error en el servidor. Intenta de nuevo.");
    }
  };

  return (
    <div className="admin-login-container">
      <div className="admin-login">
        <h2>Inicio de Sesión de Administrador</h2>
        {/* onSubmit={handleLogin} ya incluye preventDefault en la función */}
        <form onSubmit={handleLogin}>
          <label>
            Usuario:
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </label>
          <label>
            Contraseña:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          {error && <p className="error-message">{error}</p>}
          <button type="submit">Iniciar Sesión</button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
