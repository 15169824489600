import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

function Home() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch('https://riseitservice.com/api/productos-publicos/')
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Error al obtener los productos:', error));
  }, []);

  return (
    <div className="home-page-inicio">
      <section className="section-inicio info-section-inicio">
        <div className="section-content-inicio">
          <h2>Bienvenidos a RISE</h2>
          <p>Ofrecemos soluciones tecnológicas innovadoras para hacer crecer tu negocio. Descubre cómo RISE puede llevar tu empresa al siguiente nivel.</p>
          <a href="/Nosotros" className="section-button-inicio">Conoce nuestra historia</a>
        </div>
      </section>

      <section className="section-inicio products-section-inicio">
        <div className="section-content-inicio text-dark-background-inicio">
          <h2>Nuestros Productos</h2>
          <p>Soluciones personalizadas para cada necesidad empresarial.</p>
          <div className="product-grid-inicio">
            {products.map((product) => (
              <Link to={`/productos/${product.id}`} key={product.id} className="product-card-inicio">
                <img src={`https://riseitservice.com/api/media/${product.imagen}`} alt={product.nombre} className="product-image-inicio" />
                <h3 className="product-name-inicio">{product.nombre}</h3>
                <p className="product-price-inicio">
                  {product.precio.toLocaleString('es-CL', {
                    style: 'currency',
                    currency: 'CLP',
                    minimumFractionDigits: 0,
                  })}
                  CLP
                </p>
              </Link>
            ))}
          </div>
          <a href="/productos" className="section-button-inicio">Ver todos los productos</a>
        </div>
      </section>

      {/* Sección de Contacto */}
      <section className="section-inicio contact-section-inicio">
        <div className="section-content-inicio">
          <h2>Contáctanos</h2>
          <p>¿Tienes alguna duda o necesitas más información? Nos encantaría ayudarte.</p>
          <a href="/contacto" className="section-button-inicio">Contactar ahora</a>
        </div>
      </section>

      <section className="section-inicio more-info-section-inicio">
        <div className="section-content-inicio text-dark-background-inicio">
          <h2>Compromiso con la innovación</h2>
          <p>En RISE, combinamos tecnología y experiencia para aportar a tus proyectos.</p>
          <a href="/Nosotros" className="section-button-inicio">Más sobre RISE</a>
        </div>
      </section>
    </div>
  );
}

export default Home;
