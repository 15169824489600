// TerminosYCondiciones.js
import React from 'react';
import './TerminosYCondiciones.css'; // Archivo CSS para estilos personalizados

function TerminosYCondiciones() {
  return (
    <div className="terminos-y-condiciones-container">
      <h1>Términos y Condiciones de RISE</h1>
      
      <section>
        <h2>1. Aceptación de los Términos</h2>
        <p>
          Al acceder y utilizar el sitio web de <strong>RISE</strong> (en adelante, “la Empresa”), el usuario acepta cumplir con los presentes Términos y Condiciones, así como con cualquier política adicional que sea aplicable. Si no está de acuerdo con estos términos, le recomendamos no utilizar nuestro sitio ni nuestros servicios.
        </p>
      </section>

      <section>
        <h2>2. Modificaciones a los Términos</h2>
        <p>
          RISE se reserva el derecho a modificar estos Términos y Condiciones en cualquier momento. Las modificaciones entrarán en vigor al momento de su publicación en el sitio web. Recomendamos a los usuarios revisar estos términos periódicamente.
        </p>
      </section>

      <section>
        <h2>3. Uso del Sitio Web y de los Servicios</h2>
        <p>El usuario se compromete a utilizar el sitio web y los servicios de RISE únicamente con fines legales y de acuerdo con estos Términos y Condiciones. Está prohibido el uso del sitio para:</p>
        <ul>
          <li>Realizar actividades fraudulentas o ilícitas.</li>
          <li>Difundir contenido malicioso o perjudicial.</li>
          <li>Recopilar datos personales de otros usuarios sin consentimiento.</li>
        </ul>
      </section>

      <section>
        <h2>4. Propiedad Intelectual</h2>
        <p>
          Todos los contenidos del sitio web, incluidos textos, gráficos, logotipos, íconos, imágenes, videos, y software, son propiedad de RISE o de sus licenciatarios. El usuario no tiene derecho a reproducir, distribuir, modificar o explotar el contenido sin el permiso previo por escrito de la Empresa.
        </p>
      </section>

      <section>
        <h2>5. Registro y Seguridad de la Cuenta</h2>
        <p>
          Para acceder a ciertos servicios, el usuario puede necesitar crear una cuenta y proporcionar información precisa y completa. Es responsabilidad del usuario mantener la confidencialidad de su cuenta y notificar a RISE de cualquier uso no autorizado.
        </p>
      </section>

      <section>
        <h2>6. Política de Privacidad</h2>
        <p>
          La recopilación y el uso de la información personal del usuario se rigen por nuestra <strong>Política de Privacidad</strong>. Al utilizar nuestro sitio, el usuario acepta las prácticas descritas en dicha política.
        </p>
      </section>

      <section>
        <h2>7. Limitación de Responsabilidad</h2>
        <p>
          RISE no será responsable por los daños y perjuicios que pudieran derivarse del uso o imposibilidad de uso de su sitio web o de los servicios ofrecidos, incluidos daños directos, indirectos, incidentales o consecuentes. Asimismo, la Empresa no garantiza que el sitio esté libre de virus u otros elementos dañinos.
        </p>
      </section>

      <section>
        <h2>8. Enlaces a Sitios de Terceros</h2>
        <p>
          El sitio web de RISE puede contener enlaces a sitios de terceros. Estos enlaces se proporcionan únicamente como referencia, y RISE no es responsable por el contenido, políticas o prácticas de estos sitios externos.
        </p>
      </section>

      <section>
        <h2>9. Ley Aplicable y Jurisdicción</h2>
        <p>
          Estos Términos y Condiciones se regirán e interpretarán de acuerdo con la legislación vigente en [indicar país]. En caso de disputa, ambas partes se someterán a la jurisdicción de los tribunales de [indicar ciudad o región].
        </p>
      </section>

      <section>
        <h2>10. Contacto</h2>
        <p>
          Para cualquier consulta sobre estos Términos y Condiciones, puede ponerse en contacto con nosotros en:
          <br />
          <strong>Correo electrónico:</strong> <a href="mailto:rise@riseitservices.com">rise@riseitservices.com</a>
          <br />
          <strong>Teléfono:</strong> +569 56433387
          <br />
          <strong>Dirección:</strong> 13 Norte 853 Viña del Mar
        </p>
      </section>
    </div>
  );
}

export default TerminosYCondiciones;
