import React, { useState } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FaTwitter, FaInstagram, FaYoutube, FaLinkedin, FaHome, FaProjectDiagram, FaUserAlt, FaEnvelope, FaGavel, FaKey, FaPhoneAlt, FaMapMarkerAlt, FaHandHoldingHeart } from 'react-icons/fa';

function Footer() {
    const [activeSection, setActiveSection] = useState(null);

    const toggleSection = (section) => {
      setActiveSection(activeSection === section ? null : section);
    };
  return (
      <footer className="footer">
          <div className="footer-section">
              <img src="/assets/RISE2.png" alt="RISE Logo" className="logo" />
              <p>© 2024 Research, IT Services and Engineering, RISE SpA.<br />Todos los derechos reservados.</p>
              <div className="social-icons">
                  <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                  <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                  <a href="https://youtube.com" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
                  <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
              </div>
          </div>
          <div className={`footer-section ${activeSection === 'links' ? 'active' : ''}`}>
              <h4 onClick={() => toggleSection('links')}>Enlaces Rápidos</h4>
              <ul>
                  <li><Link to="/"><FaHome /> Inicio</Link></li>
                  <li><Link to="/Productos"><FaProjectDiagram /> Proyectos</Link></li>
                  <li><Link to="/Nosotros"><FaUserAlt /> Quiénes somos</Link></li>
                  <li><Link to="/Contacto"><FaEnvelope /> Contacto</Link></li>
              </ul>
          </div>
          <div className={`footer-section ${activeSection === 'policies' ? 'active' : ''}`}>
              <h4 onClick={() => toggleSection('policies')}>Políticas</h4>
              <ul>
                  <li><Link to="/aviso_legal"><FaGavel /> Aviso Legal</Link></li>
                  <li><Link to="/politica_privacidad"><FaKey /> Política de privacidad</Link></li>
                  <li><Link to="/terminos_y_condiciones"><FaGavel /> Términos y condiciones</Link></li>
                  <li><Link to="/politicas_de_uso_y_licencias"><FaKey /> Política de uso de licencias</Link></li>
                  <li><a href="assets/PROGRAMA_INTEGRIDAD_Y_CODIGO_ETICA_RISE_SpA.pdf" download><FaHandHoldingHeart /> Programa de integridad y código de ética </a></li>
              </ul>
          </div>
          <div className={`footer-section ${activeSection === 'contact' ? 'active' : ''}`}>
              <h4 onClick={() => toggleSection('contact')}>Contacto</h4>
              <ul>
              <li><FaEnvelope /> rise@riseitservices.com</li>
              <li><FaPhoneAlt /> +569 56433387</li>
              <li><FaMapMarkerAlt /> 13 Norte 853 Viña del Mar</li>
              </ul>
          </div>
      </footer>
  );
}

export default Footer;
