import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Detalle.css';

function Detalle() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Esto desplaza la página al inicio
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch('https://riseitservice.com/api/productos-publicos/');
        if (!response.ok) {
          throw new Error('Error al obtener el producto');
        }
        const data = await response.json();
        const selectedProduct = data.find((p) => p.id === parseInt(id));

        if (!selectedProduct) {
          throw new Error('Producto no encontrado');
        }
        setProduct(selectedProduct);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!product) {
    return <div>Producto no encontrado</div>;
  }

  const handleQuoteClick = () => {
    navigate(`/contacto?producto=${product.nombre}`);
  };

  // Formateo de precio con toLocaleString
  const formattedPrice = product.precio.toLocaleString('es-CL');

  return (
    <div className="product-detail">
      <h1>{product.nombre}</h1>
      <div className="product-image-container">
        <img src={product.imagen} alt={product.nombre} className="product-image-detalle" />
      </div>
      <h2>Descripción</h2>
      <p>{product.descripcion}</p>
      <div className="product-price">
        <span className="price-label">Cotización aproximada</span>
        <span className="price-amount">${formattedPrice} CLP</span>
      </div>
      <button className="quote-button" onClick={handleQuoteClick}>Cotizar Proyecto</button>
    </div>
  );
}

export default Detalle;
