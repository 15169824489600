import React from 'react';
import './Nosotros.css';

function Nosotros() {
    return (
        <div className="about-container">
            <h1>Nosotros</h1>
            <div className="about-content">
                <img src="/assets/RISE2.png" alt="RISE Logo" className="about-logo" />
                <p>
                    RESEARCH, IT SERVICES AND ENGINEERING, RISE SpA, es una empresa chilena con sede central en la ciudad de Viña del Mar, región de Valparaíso, que se dedica a la consultoría en ingeniería, investigación, educación y servicios IT.
                    <br /><br />
                    Fue fundada en 2024 gracias a la visión de María Luisa Mora-Urrutia, Magíster en Física, Profesora Universitaria e Investigadora formada en UTFSM, y de Lázaro Bertín Mora, Ingeniero Informático formado en DUOC UC.                    
                    <br /><br />
                    En RISE research and engineering SpA, creemos en el poder de la tecnología para transformar y optimizar tus negocios. Nuestro equipo multidisciplinario une experiencia en investigación en ciencias fundamentales y aplicadas, tecnologías de la información (IT) e ingeniería, asesoría técnica y capacitación; para brindar soluciones personalizadas que responden a las necesidades específicas de cada cliente. Siempre bajo la premisa fundamental de nuestro quehacer: el trato horizontal y el enfoque orientado al cliente.
                    <br /><br />
                    Nuestros principales clientes incluyen entidades públicas y privadas, municipalidades, centros educativos, centros de investigación, empresas de diversos tamaños y personas particulares. Nos especializamos en servicios de infraestructura de IT, desarrollo de software a medida y soporte técnico integral, asesorías en ingeniería de software y servicios IT en general, asesoría técnica y educativa proporcionando capacitaciones en estos ámbitos, desarrollo de investigación científica aplicada para dar soluciones tecnológicas en las áreas de servicios informáticos, educación escolar y universitaria, construcción y obras civiles.
                    <br /><br />
                    Cada proyecto que asumimos es una oportunidad para impulsar la eficiencia y el crecimiento de nuestros clientes, asegurando una implementación efectiva y una experiencia tecnológica sin interrupciones.
                    <br /><br />
                    Nuestro compromiso es acompañarte en cada paso de tu transformación digital, ofreciendo herramientas y estrategias innovadoras que permiten a tu empresa adaptarse y destacar en un entorno competitivo. En RISE SpA, la innovación y el desarrollo de soluciones de alto impacto son el motor que nos impulsa a avanzar, creando un impacto positivo y duradero en el mundo empresarial.
                </p>
            </div>
        </div>
    );
}

export default Nosotros;
